/**
 * Tiny Slider
 */
import { tns } from "tiny-slider/src/tiny-slider";

/**
 * On Sale Carousel (home.html)
 * @type {TinySliderInstance}
 */
if (document.querySelectorAll('.onsale-carousel').length > 0) {
    const onsaleCarousel = tns({
        container: ".onsale-carousel",
        nested: "inner",
        responsive: {
            320: {
                edgePadding: 12,
                gutter: 6,
                items: 2,
            },
            700: {
                edgePadding: 0,
                gutter: 6,
                items: 3,
            },
            1400: {
                edgePadding: 0,
                gutter: 12,
                items: 4,
            },
            1920: {
                edgePadding: 0,
                gutter: 12,
                items: 4,
            },
        },
        controls: false,
        navPosition: "bottom",
        mouseDrag: true,
        autoplay: false,
        autoplayButtonOutput: false,
    });
}

/**
 * Cross-selling Carousel (product.html)
 * @type {TinySliderInstance}
 */
if (document.querySelectorAll('.cross-selling-carousel').length > 0) {
    const crossSellingCarousel = tns({
        container: ".cross-selling-carousel",
        nested: "inner",
        responsive: {
            320: {
                edgePadding: 12,
                gutter: 6,
                items: 2,
            },
            700: {
                edgePadding: 0,
                gutter: 6,
                items: 3,
            },
            1400: {
                edgePadding: 0,
                gutter: 12,
                items: 4,
            },
            1920: {
                edgePadding: 0,
                gutter: 12,
                items: 4,
            },
        },
        controls: false,
        navPosition: "bottom",
        mouseDrag: true,
        autoplay: false,
        autoplayButtonOutput: false,
    });
}

/**
 * Upselling Carousel (product.html)
 * @type {TinySliderInstance}
 */
if (document.querySelectorAll('.upselling-carousel').length > 0) {
    const upsellingCarousel = tns({
        container: ".upselling-carousel",
        nested: "inner",
        responsive: {
            320: {
                edgePadding: 12,
                gutter: 6,
                items: 2,
            },
            700: {
                edgePadding: 0,
                gutter: 6,
                items: 3,
            },
            1400: {
                edgePadding: 0,
                gutter: 12,
                items: 4,
            },
            1920: {
                edgePadding: 0,
                gutter: 12,
                items: 4,
            },
        },
        controls: false,
        navPosition: "bottom",
        mouseDrag: true,
        autoplay: false,
        autoplayButtonOutput: false,
    });
}

/**
 * Upselling Carousel Cart (product.html)
 * @type {TinySliderInstance}
 */
if (document.querySelectorAll('.upselling-carousel-cart').length > 0) {
    const upsellingCarouselCart = tns({
        container: ".upselling-carousel-cart",
        nested: "inner",
        responsive: {
            320: {
                edgePadding: 0,
                gutter: 6,
                items: 4,
            },
            1400: {
                edgePadding: 0,
                gutter: 12,
                items: 4,
            },
        },
        controls: false,
        navPosition: "bottom",
        mouseDrag: true,
        autoplay: false,
        autoplayButtonOutput: false,
    });
}